define("ember-modal-dialog/components/in-place-dialog", ["exports", "@ember-decorators/component", "@ember/component", "ember-modal-dialog/templates/components/in-place-dialog"], function (_exports, _component, _component2, _inPlaceDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  let InPlaceDialog = _exports.default = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_inPlaceDialog.default), _dec(_class = _dec2(_class = class InPlaceDialog extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "containerClass", null);
    }
    // passed in

    init() {
      super.init(...arguments);
      this.containerClassNames = ['ember-modal-dialog', 'ember-modal-dialog-in-place', 'emd-in-place']; // set this in a subclass definition
    }

    get containerClassNamesString() {
      return this.containerClassNames?.join && this.containerClassNames?.join(' ') || this.containerClassNames || '';
    }
  }) || _class) || _class);
});